import React from "react";

const VerifyAccount = ({ title, description }) => {
  return (
    <div className="tw-flex tw-items-center tw-justify-center">
      <div className="tw-py-[70px] tw-px-[20px] tw-w-[500px]">
        <h1 className="tw-text-center">{title}</h1>
        <p className="tw-text-[14px] tw-leading-[24px] tw-font-sans tw-text-center tw-mt-[30px] tw-mb-[60px] tw-tracking-[0.2px]">
          {description}
        </p>
      </div>
    </div>
  );
};

export default VerifyAccount;
